<template>
  <transition name="menu">
    <form-surat :is-edit="true"/>
  </transition>
</template>

<script>
import formSurat from '../components/formSurat2'

export default {
  name: 'EditformSurat2',
  components: { formSurat }
}
</script>

